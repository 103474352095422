import { Theme as MaterialTheme, PaletteOptions, ThemeOptions, createTheme } from '@mui/material/styles';
import { Media } from '@shared/schema/common';
import { Shape } from '@mui/system';
import { TypeBackground, TypeText } from '@mui/material/styles/createPalette';

type BlockTheme = MaterialTheme & BlockThemeExtensions;

interface BlockPalette extends PaletteOptions {

  background: Partial<TypeBackground> & {
    /**
     * The background color of papers on the top of a background paper
     */
    innerPaper: string;
    comment: string;
    graph: string;
    lightInnerPaper: string;
  };
  text: Partial<TypeText> & {
    lightgrey: string;
  }
}
interface BlockThemeExtensions {
  animations: {
    hovers: {
      /**
       * Hover on card defining some basic concept on the UI like
       * project, query or question.
       */
      card?: string;
    }
  },
  palette: BlockPalette;
  shape: Shape & {
    smokeGlassBlur: number;
    imagePlaceholder: string;
    maxEditorAreaWidth: number;
  },
  constants: {
    headerHeight: string;
    bottomHeight: string
  }
}
/**
 * Cast Emotion's Theme type to Material theme
 */
declare module '@emotion/react' {
  export interface Theme extends BlockTheme {
    custom: string; // Needed to make
  }
}

export const COLOR_APPLE = 'rgba(0, 0, 0, 1)';
export const COLOR_GOOGLE = '#4285F4';
export const COLOR_FACEBOOK = '#1877F2';
export const COLOR_LINKEDIN = '#2D6798';

export const chipColors = {
  draft: {
    backgroundColor: '#ffba66',
    color: '#8e4e00',
  },
  active: {
    backgroundColor: '#98FB98',
    color: '#004600',
  },
  closed: {
    backgroundColor: '#93d2fa',
    color: '#00008B',
  },
  facilitator: {
    color: '#FF8C00',
  },
  panelist: {
    color: '#4Bc2fA',
  },
};

// TODO Create a theme with this tool: https://bareynol.github.io/mui-theme-creator/

type BlockOptions = ThemeOptions & BlockThemeExtensions;

const ITEM_HOVER_STYLE = 'transform: scale(1.03);' +
  'box-shadow: 0px 10px 13px -7px #000000, 11px 15px 15px 3px rgba(0,0,0,0);';

const themeOptions: BlockOptions = {
  animations: {
    hovers: {
      card: ITEM_HOVER_STYLE,
    },
  },
  shape: {
    borderRadius: 12,
    smokeGlassBlur: 8,
    imagePlaceholder: '/images/ImagePlaceholder.png',
    maxEditorAreaWidth: 1200,
  },
  constants: {
    headerHeight: '80px',
    bottomHeight: '72px',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          margin: 0;
        }
        body {
          margin: 0;
        }
        `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
  typography: {
    allVariants: {
      color: '#D3D3D3',
    },
    fontFamily: [
      'Poppins',
      'Lato',
      'Nunito',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '2rem',
      marginBottom: '1rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '1.6rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.4rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.3rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.1rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: 400,
    },
    body1: {
      marginTop: '0.75rem',
      marginBottom: '1rem',
      fontWeight: 300,
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      // main: '#1B628A',
      main: '#4Bc2fA',
      contrastText: '#fff',
    },
    secondary: {
      main: '#008048',
    },
    action: {
      disabledBackground: '#C2C2C2',
      disabled: '#ffffff80',
    },
    error: {
      main: '#E94926',
    },
    background: {
      // default: '#101018',
      default: '#202030',
      // paper: 'rgba(40,40,40,0.85)',
      paper: 'rgba(50,50,50,0.85)',
      comment: '#55595B',
      innerPaper: '#4f4f6f',
      graph: '#C4C4C4',
      lightInnerPaper: '#50516f',
    },
    text: {
      primary: 'rgba(230,232,255,0.8)',
      secondary: '#E5E5E5',
      lightgrey: '#D3D3D3',
    },
  },
};

export const EMPTY_MEDIA_RECORD: Media = {
  type: 'photo',
  photoURL: themeOptions.shape.imagePlaceholder,
  tags: [],
};

// Create a theme instance.
const theme = createTheme(themeOptions) as BlockTheme;

export default theme;
