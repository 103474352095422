// / <reference types="@welldone-software/why-did-you-render" />
import React from 'react';

const REPORT_RERENDERINGS = true;

if (REPORT_RERENDERINGS && typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}
