import '../_dev/wdyr';
import 'whatwg-fetch';

import { Theme as EmotionTheme, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MaterialThemeProvider, StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
// import * as Sentry from '@sentry/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps, NextWebVitalsMetric } from 'next/app';

import { bootstrapRoles } from '@mindhiveoy/auth';
import { schema } from '@shared/schema/schema';
import Head from 'next/head';
import config from 'config';
import theme from 'theme';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
bootstrapRoles(schema.roles as any);

// TODO Setup Sentry
// config.environment.target.match(/^(staging|production)$/) && Sentry.init({
//   dsn: config.sentry.dsn,
// });

const App = ({
  Component,
  pageProps,
  router,
}: AppProps): JSX.Element => {
  const [state,] = React.useState({});

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <StylesProvider injectFirst>
        <MaterialThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme as EmotionTheme}>
            <CssBaseline />
            {/* <AuthProvider
              adapter={firebaseAuthPlatformAdapter}
              signIn={SignIn as unknown as never}
              state={state}
              strategy={AuthenticationStrategy.EXPLICIT}
            > */}
            <Component {...pageProps} key={router.route}/>
            {/* </AuthProvider> */}
          </EmotionThemeProvider>
        </MaterialThemeProvider>
      </StylesProvider>
    </>
  );
};

// eslint-disable-next-line require-jsdoc
export function reportWebVitals(metric: NextWebVitalsMetric): void {
  if (config.environment.target === 'development') {
    console.log(metric);
  }
  // TODO Report vitals
}

export default appWithTranslation(App);
