/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */
import {
  DataType, FieldType, FirestoreSchemaModel, doc, schemaUserFields,
} from '@mindhiveoy/firebase-schema';
import { ShortUserRoleSet, UserRoleSet, userRole, userRoleContext, } from '@mindhiveoy/auth';

import { EmailData } from './emailCenter';
import { User } from './users/user';

const roleContexes = {
  platform: userRoleContext({
    reference: 'users',
    permission: {
      full: ['system',],
      read: ['anyone',],
      update: ['anyone',],
      list: ['anyone',],
      write: ['anyone',],
    },
    level: 0,
    defaultRole: 'anyone',
  }),
};

// TODO: Merge ContextId and this UserRoleContextId. They are the same.
export type UserRoleContextId = keyof typeof roleContexes;

/**
 * Custom role's for the platform
 */
const roles = {
  /**
   * Normal user with account
   */
  user: userRole<UserRoleContextId>({
    name: 'User',
    context: 'platform',
  }),
};

export type UserRoleId = UserRoleSet<keyof typeof roles>;

// TODO autogenerate short user role id's
export type ShortUserRoleId = ShortUserRoleSet<'u'>;

export const schema: FirestoreSchemaModel<UserRoleId, ShortUserRoleId> = {

  packages: [
    'auth',
    'firestore',
    'functions',
    'messaging',
    'storage',
    'performance',
  ],
  roles,
  roleContext: {
    contextPath: [],
    roleContexes,
  },

  collections: {
    users: {
      doc: doc<User<ShortUserRoleId>, UserRoleId>({
        paramId: 'uid',
        info: 'All Block users.',

        access: {
          functions: {
            isOwner: 'return request.auth != null && request.auth.uid == uid;',
            isAuthenticated: 'return request.auth != null',
          },
          permissions: {
            read: ['system', 'owner',],
            list: ['system',],
            create: ['isAuthenticated',],
            update: ['system', 'owner',],
          },
        },

        fields: {
          ...(schemaUserFields<'', '', UserRoleId>() as any),

          emailVerified: {
            dataType: DataType.Boolean,
            type: FieldType.Descriptor,
            description: 'Is email verified or not',
          },
        },
      }),
    },

    mailCenter: {
      doc: doc<EmailData, UserRoleId>({
        paramId: 'emailtoken',
        info: 'MailCenter to handle all emails, that leave from app.',
        fields: {
          to: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
            required: true,
          },
          status: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
          },
          htmlPart: {
            dataType: DataType.Text,
            type: FieldType.Descriptor,
            required: true,
          },
          textPart: {
            dataType: DataType.DocumentId,
            type: FieldType.Descriptor,
            required: true,
          },
          fromUser: {
            dataType: DataType.Integer,
            type: FieldType.Descriptor,
          },
          subject: {
            dataType: DataType.DateAndTime,
            type: FieldType.Descriptor,
          },
          error: {
            dataType: DataType.Map,
            type: FieldType.Descriptor,
          },
        },
      }),
    },
  },
};
